<template>
    <div class="dashboard cust-page-detail">
        <div>
            <div class="item">
                <div class="item__detail-breadth">
                    <div class="item__detail">
                        <h2 class="item__title">JANコード</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.jan_code | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">商品名</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.item_name | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">品番</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.item_number | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">現在庫</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.stock_now }} {{this.stockdataDetail.unit}}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">単位</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.unit | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">NET</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.net | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">卸価格</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.wholesale | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">定価</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.list_price | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">原価</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.cost_price | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">入数</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.stock_in | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">元払</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.prepayment | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">重量（kg）</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.stock_weight | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">最終更新日時</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.final_updated_at | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">その他1</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.spare1 | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">その他2</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.spare2 | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">その他3</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.spare3 | dataAvailable }}
                        </div>
                    </div>
                    <div class="item__detail">
                        <h2 class="item__title">その他4</h2>
                        <div class="item__input">
                            {{ this.stockdataDetail.spare4 | dataAvailable }}
                        </div>
                    </div>
                </div>
                <div class="l-mt6">
                    <button class="button--oval--other" type="button" @click="$_goBack()">戻る</button>
                </div>
            </div>
        </div>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    export default {
        "filters": {
            dataAvailable(val) {
                let data = val ?? "－";
                if (!String(data).match(/\S/g)) {
                    data = "－";
                }
                return data;
            },
        },
        "mixins": [
            common,
        ],
        "props": {
            "janCode": {
                "required": true,
                "type": String,
            },
            "stockPlaceCode": {
                "required": true,
                "type": String,
            },
        },
        data() {
            return {
                "stockdataDetail": {},
                "params": {
                    "jan": this.janCode,
                    "place": this.stockPlaceCode,
                },
            };
        },
        created() {
            this.$root.pagetitle = "品番詳細";
            this.fetchingStockDataDetail();
        },
        "methods": {
            fetchingStockDataDetail() {
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/stock/detail`,
                    this.$_requestConfig("params")
                ).then(response => {
                    this.stockdataDetail = response.data;
                }).catch(() => {
                    this.$_modalError("itemDetailFetchError");
                });
            },
        },
    };
</script>
